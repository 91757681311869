import React from "react";
import { Box, Flex } from "theme-ui";
import map from "lodash/fp/map";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import uniqBy from "lodash/fp/uniqBy";
import startCase from "lodash/startCase";

import { useSlider } from "utils";

const TagsBox = ({
  tags: initialTags,
  sx,
  className,
  handleClick,
  tagId,
  facilityTypePlurals,
}) => {
  const { sliderRef, scrollTo, scrollStatus } = useSlider();

  const formatTagsData = (tags) => {
    return flow(
      uniqBy("type"),
      map((facility) => {
        const numberOfType = flow(
          filter(({ type }) => type === facility.type),
          (groupedType) => groupedType.length
        )(tags);

        return { ...facility, numberOfType };
      })
    )(tags);
  };

  const tags = formatTagsData(initialTags);

  const showIf = (side) => scrollStatus !== side && scrollStatus !== null;

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
      className={className}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
          gap: "8px",
        }}
        ref={sliderRef}
      >
        {/* TODO: Needs Icon available in CMS */}
        {/* TODO: Is there another property variable available that distinguishes on property fields? */}
        {tags.map((tag) => (
          <Box
            key={tag.id}
            // className={tagId === tag.id ? "isActive" : ""}
            sx={{ variant: "pills", width: "max-content" }}
            onClick={handleClick && (() => handleClick(tag))}
          >
            {`${
              tag.numberOfType > 1
                ? facilityTypePlurals[startCase(tag.type)]
                : tag.type
            } (${tag.numberOfType})`}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default TagsBox;
