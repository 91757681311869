import React from "react";
import { Box, Text, Heading } from "theme-ui";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import startCase from "lodash/startCase";

import { useVariantByBreakpoint } from "utils";
import Slider from "components/Slider";
import CardWithBlackBackground from "components/card/CardWithBlackBackground";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import { CARD_RESOLUTIONS } from "components/card/sizes";
import Link from "components/Link";
import { facilityPageUrl } from "utils/urls";

const mapWithIndex = map.convert({ cap: false });

export default function PropertyFacilitiesSlider({
  facilities,
  facilityTypePlurals,
}) {
  const variantSelector = useVariantByBreakpoint();

  const groupedFacilities = flow(
    groupBy("type"),
    mapWithIndex((value, key) => ({ type: key, facilities: value }))
  )(facilities);

  const cardOrientation = "portrait";
  const cardSizeDesktop = "medium";

  return (
    <Slider
      className="fullSize"
      sx={{ mt: [3, 4, 6] }}
      childWidth={parseInt(
        CARD_RESOLUTIONS[cardOrientation][cardSizeDesktop]?.width,
        10
      )}
    >
      {groupedFacilities.map(({ type, facilities }, index) => {
        const singleFacilitiy = facilities.length === 1;
        return (
          <Link to={facilityPageUrl(facilities[0])}>
            <CardWithBlackBackground
              key={type}
              type="white"
              orientation="portrait"
              size={variantSelector(["small", null, cardSizeDesktop])}
              image={getImage(facilities[0]?.heroImage?.localFile)}
            >
              <Box sx={{ width: "100%", px: 3, py: 4 }}>
                {/* <Box>TODO Add Icon</Box> */}
                {singleFacilitiy && (
                  <Text variant="body2" color="systemWhite">
                    {startCase(type)}
                  </Text>
                )}
                <Heading as="h6" variant="heading6" color="systemWhite">
                  {singleFacilitiy
                    ? facilities[0]?.name
                    : `All ${facilityTypePlurals[startCase(type)]}`}
                </Heading>
              </Box>
            </CardWithBlackBackground>
          </Link>
        );
      })}
    </Slider>
  );
}
