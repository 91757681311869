import React, { useRef, useEffect } from "react";
import { Box, Flex } from "theme-ui";
import { Close } from "@material-ui/icons";

const Modal = ({ open, children, onClose }) => {
  const callbackRef = useRef(null);

  useEffect(() => {
    if (open) {
      callbackRef.current = (e) => {
        if (e.key === "Escape") {
          onClose();
        }
      };

      document.addEventListener("keydown", callbackRef.current);
    } else if (callbackRef.current) {
      document.removeEventListener("keydown", callbackRef.current);
      callbackRef.current = null;
    }

    return () => {
      if (callbackRef.current) {
        document.removeEventListener("keydown", callbackRef.current);
      }
    };
  }, [open]);

  if (!open) return null;
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "screen-w",
          height: "screen-h",
          top: 0,
          left: 0,
          zIndex: "backdrop",
          bg: "backdrop",
        }}
        onClick={onClose}
        className="outside"
      />
      <Flex
        sx={{
          position: "fixed",
          top: [2, 6, 10],
          right: [2, 6, 10],
          background: "backdrop",
          zIndex: "modal-button",
          p: 3,
          borderRadius: "rounded",
          color: "systemWhite",
          fontSize: 6,
        }}
        className="outside"
      >
        <Close onClick={onClose} />
      </Flex>
      <Flex
        onK
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "modal",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="outside"
      >
        {children}
      </Flex>
    </>
  );
};

export default Modal;
