import { isArray } from "lodash";
import React from "react";
import { Paragraph } from "theme-ui";

function forEachLine(lines) {
  return (callback) => {
    if (!isArray(lines)) return callback(lines);

    return lines.map((val) => (val ? callback(val) : null));
  };
}

const LimitedLines = ({
  lines = 0,
  sx = {},
  fixedHeight = false,
  children,
  ...props
}) => {
  const formatLines = forEachLine(lines);
  const height = formatLines((val) =>
    val === 0 ? "unset" : `calc(var(--line-height) * ${val} * 1em)`
  );
  return (
    <Paragraph
      sx={{
        maxHeight: height,
        display: "-webkit-box",
        WebkitLineClamp: formatLines((val) => (val === 0 ? "unset" : val)),
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        height: fixedHeight ? height : "unset",
        textOverflow: "ellipsis",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Paragraph>
  );
};

export default LimitedLines;
