import React, { Children, useRef } from "react";

import useSlider from "../utils/useSlider";
import { Box, Grid, IconButton } from "theme-ui";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useDimensions } from "utils/useDimensions";

const Slider = ({ children, sx, className, childWidth }) => {
  const { sliderRef, scrollStatus, scrollTo } = useSlider({ hasPadding: true });

  const containerRef = useRef(null);
  const { width } = useDimensions(containerRef);

  const showButtons = childWidth
    ? width - childWidth * children.length < 100
    : children.length > 2;

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
      ref={containerRef}
      className={className}
    >
      <Grid
        sx={{
          gap: 4,
          overflowX: "auto",
          width: "full",
          scrollSnapType: "x mandatory",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          gridAutoFlow: "column",
          gridAutoColumns: "max-content",
          pb: 10,
          scrollMarginLeft: [4, "contentSpace"],
        }}
        ref={sliderRef}
      >
        <Box
          sx={{
            width: [0, "contentSpace"],
          }}
        />
        {Children.map(children, (child, index) => (
          <Box key={index} sx={{ scrollSnapAlign: "center", maxWidth: "90vw" }}>
            {child}
          </Box>
        ))}
        <Box
          sx={{
            width: [0, "contentSpace"],
          }}
        />
      </Grid>
      {showButtons && (
        <Box
          sx={{
            position: "absolute",
            bottom: "50%",
            right: "contentSpace",
            transform: "translateY(-50%)",
            display: ["none", null, "flex"],
            gap: 2,
          }}
        >
          <IconButton
            variant="icon.solid"
            sx={{ justifySelf: "center" }}
            onClick={scrollTo("left")}
            disabled={scrollStatus === "left"}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            variant="icon.solid"
            sx={{ justifySelf: "center" }}
            onClick={scrollTo("right")}
            disabled={scrollStatus === "right"}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Slider;
