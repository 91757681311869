import React, { forwardRef } from "react";
import { useEffect, useRef, useState } from "react";
import { Box, Text, Grid, IconButton } from "theme-ui";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import Image from "components/Image";

import { useSlider } from "utils";

const buttonStyle = {
  width: 10,
  height: 10,
  color: "systemWhite",
  display: ["none", "flex"],
  placeItems: "center",
  background: "backdrop",
};

const styles = {
  wrapper: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    mx: "auto",
    textAlign: "center",
    boxSizing: "border-box",
    flexDirection: "column",
    gap: 6,
    p: [0, 2],
  },

  leftClick: {
    ...buttonStyle,
    transform: [null, "translateX(0.5rem)", "translateX(0)"],
    svg: {
      ml: 2,
    },
  },

  rightClick: {
    ...buttonStyle,
    transform: [null, "translateX(-0.5rem)", "translateX(0)"],
    right: 5,
  },
};

const ImageSlider = forwardRef(
  (
    {
      imageList,
      autoPlayInterval,
      showArrowControls = true,
      bgColor = "none",
      sx,
      ...props
    },
    ref
  ) => {
    const { sliderRef, scrollStatus, scrollTo } = useSlider();
    const statusIs = (comp) => scrollStatus === comp;
    const [active, setActive] = useState("1");

    const observerRef = useRef();
    const refChilds = useRef(null);

    useEffect(() => {
      if (autoPlayInterval) {
        let autoSlider = setInterval(setNextImage, autoPlayInterval);
        return () => clearInterval(autoSlider);
      }
    }, [active]);

    useEffect(() => {
      ref.current = sliderRef.current;
      sliderRef.current.scrollLeft = 0;
      if (sliderRef.current) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            if (entries.length !== 1) return;
            const [entry] = entries;

            const { isIntersecting } = entry;
            const { position } = entry.target.dataset;
            if (isIntersecting && position) {
              setActive(position);
            }
          },
          {
            root: sliderRef.current,
            threshold: 0.4,
          }
        );
        sliderRef.current.childNodes.forEach((node) => {
          observerRef.current.observe(node);
        });
      }
      return () => {
        if (sliderRef.current)
          sliderRef.current.childNodes.forEach((node) => {
            observerRef.current.unobserve(node);
          });
      };
    }, [sliderRef]);

    return (
      <Grid
        columns={["auto", "auto 1fr auto"]}
        gap={4}
        sx={{
          alignItems: "center",
          ...sx,
        }}
        {...props}
      >
        <IconButton
          variant="icon.light"
          sx={styles.leftClick}
          disabled={statusIs("left")}
          onClick={scrollTo("left")}
        >
          <ArrowBackIos />
        </IconButton>
        <Grid
          sx={{
            gap: [6, 4],
            overflowX: "auto",
            width: "full",
            scrollSnapType: "x mandatory",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            gridAutoFlow: "column",
            gridAutoColumns: "max-content",
            scrollMarginLeft: [4, 6, "contentSpace"],
            scrollMarginRight: [4, 6, "contentSpace"],
            justifyItems: "stretch",
          }}
          ref={sliderRef}
        >
          <Box sx={{ width: 2 }} />
          {imageList.map((image, index) => (
            <Box
              ref={refChilds}
              data-position={index + 1}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "90vw",
                maxHeight: "90vh",
                maxWidth: "68rem",
                scrollSnapAlign: "center",
              }}
            >
              <Image
                objectFit="cover"
                image={image}
                sx={{ width: "full", height: "full" }}
                alt="image"
              />
            </Box>
          ))}
          <Box sx={{ width: 2 }} />
        </Grid>
        <IconButton
          variant="icon.light"
          sx={styles.rightClick}
          disabled={statusIs("right")}
          onClick={scrollTo("right")}
        >
          <ArrowForwardIos />
        </IconButton>
        <Box
          sx={{
            width: "full",
            textAlign: "center",
            gridColumn: ["1", "span 3"],
          }}
        >
          <Text variant="caption" sx={{ color: "grey.0", mt: 4 }}>
            {active}/{imageList.length}
          </Text>
        </Box>
      </Grid>
    );
  }
);

export default ImageSlider;
