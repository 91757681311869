import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Flex } from "theme-ui";
import MenuSlider from "./MenuSlider";
import cn from "classnames";

export const FROM_TOP = "20%";
export const FROM_BOTTOM = "20%";

const MOVEMENT_OFFSET = -150;

const StikyMenu = ({ items, sx, className, ...props }) => {
  const [active, setActive] = useState(items[0]?.id ?? "");
  const pauseObserverRef = useRef(false);
  const buttonsList = useRef(new Array(items.length));
  const sliderRef = useRef();

  const scrollToBuilder = (itemId) => (e) => {
    setActive(itemId);
    pauseObserverRef.current = true;
    setTimeout(() => {
      pauseObserverRef.current = false;
    }, 3000);
    const element = document.getElementById(itemId);
    sliderRef.current.scrollTo({
      left: e.target.offsetLeft + MOVEMENT_OFFSET,
      behavior: "smooth",
    });
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    function findId(id) {
      return items.findIndex((item) => item.id === id);
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.length > 2 || pauseObserverRef.current) return;
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          const isBottom =
            entry.rootBounds.width / 2 < entry.boundingClientRect.top;

          if (isBottom && !isIntersecting) {
            const prevIndex =
              items.findIndex((item) => item.id === entry.target.id) - 1;
            if (prevIndex < 0) return;
            setActive((prevValue) => {
              const currentIndex = findId(prevValue);

              if (currentIndex <= prevIndex) {
                return prevValue;
              }
              sliderRef.current.scrollTo({
                left: buttonsList.current[prevIndex].offsetLeft,
                behavior: "smooth",
              });
              return items[prevIndex].id;
            });
          }

          if (isBottom) return;
          const currentIndex = findId(entry.target.id);
          sliderRef.current.scrollTo({
            left:
              buttonsList.current[currentIndex].offsetLeft + MOVEMENT_OFFSET,
            behavior: "smooth",
          });

          setActive(entry.target.id);
        });
      },
      {
        rootMargin: `-${FROM_TOP} 0% -${FROM_BOTTOM} 0%`,
        threshold: 0.8,
      }
    );
    items.forEach((item) => {
      observer.observe(document.getElementById(item.id));
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <Box
      sx={{
        position: "sticky",
        zIndex: "stiky-menu",
        top: [14, null, 0],
        background: "background",
        mb: [11, 15, 20],
        ...sx,
      }}
      className={cn("fullSize", className)}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: "column",
          pt: 3,

          maxWidth: "maxContent",
          width: "full",
          px: [4, 10],
          mx: "auto",
        }}
      >
        <MenuSlider sx={{ flex: 1 }} gap={10} ref={sliderRef}>
          {items.map((item, index) => (
            <Button
              key={`nav-item-${item.id}`}
              variant="segment"
              className={item.id === active ? "isSelected" : ""}
              onClick={scrollToBuilder(item.id)}
              ref={(ref) => {
                buttonsList.current[index] = ref;
              }}
            >
              {item.text}
            </Button>
          ))}
          <Box sx={{ p: 5 }}></Box>
        </MenuSlider>
        <Divider sx={{ width: "full", margin: 0 }} />
      </Flex>
    </Box>
  );
};

export default StikyMenu;
