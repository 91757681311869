import React, { useMemo, useRef, useState } from "react";
import { Box, Button, Grid } from "theme-ui";
import Collections from "@material-ui/icons/Collections";

import ImageSlider from "components/ImageSlider";
import Modal from "components/Modal";
import MediaContent from "components/MediaContent";

const PhotoGallery = ({ medias, sx, className }) => {
  const [open, setOpen] = useState(false);

  const images = useMemo(
    () =>
      medias
        .filter((media) => media.type === "image")
        .map((media) => media.image),
    [medias]
  );

  const sliderRef = useRef(null);

  const isMoreThanTwo = medias.length > 2;

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <Grid
        sx={{
          gridTemplateColumns: ["2fr 1fr", "1fr 240px", "1fr 400px"],
          gap: 2,
          width: "full",
          height: "full",
          ...sx,
        }}
        className={className}
      >
        <MediaContent
          media={medias[0]}
          sx={{
            gridColumn: isMoreThanTwo ? "1" : "1 / span 2",
            gridRow: "1 / span 2",
          }}
        />
        {isMoreThanTwo ? (
          <>
            <MediaContent
              media={medias[1]}
              sx={{ gridColumn: 2 }}
              alt="Gallery image"
            />
            <MediaContent
              media={medias[2]}
              sx={{ gridColumn: 2, gridRow: 2 }}
              alt="Gallery image"
            />
          </>
        ) : null}
        <Button
          variant="buttons.onDark"
          sx={{
            gridColumn: medias.length > 0 ? "1 / span 2" : [2, null, 1],
            gridRow: 2,
            placeSelf: "self-end",
            m: [4, null, 6],
          }}
          onClick={openModal}
        >
          <Box
            sx={{
              display: ["none", "inline-block"],
              width: 6,
              height: 6,
              fontSize: 6,
              mr: 2,
            }}
          >
            <Collections />
          </Box>
          +{images.length}
        </Button>
      </Grid>
      <Modal open={open} onClose={closeModal}>
        <ImageSlider
          sx={{
            maxWidth: "maxContent",
            mx: "auto",
            width: "screen-w",
          }}
          imageList={images}
          onClick={(e) =>
            e.target === sliderRef.current.children[0] && setOpen(false)
          }
          ref={sliderRef}
        />
      </Modal>
    </>
  );
};

export default PhotoGallery;
