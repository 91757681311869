import React from "react";
import { Box, Flex, Text } from "theme-ui";
import Link from "components/Link";
import ExtraData from "components/Footer/ExtraData";
import { graphql, useStaticQuery } from "gatsby";

const Footer = ({ sx, ...props }) => {
  const { config } = useStaticQuery(graphql`
    query FooterConfig {
      config: strapiAppConfig {
        privacyPolicy: privacy_policy {
          url
        }
        termsConditions: terms_conditions {
          url
        }
      }
    }
  `);

  const privacyUrl = config?.privacyPolicy?.url;
  const termsUrl = config?.termsConditions?.url;

  return (
    <Box
      sx={{
        bg: "grey.200",
        color: "text.disabled",
        py: [6, 10],
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          maxWidth: "maxContent",
          p: [4, 10],
          mx: "auto",
        }}
      >
        <ExtraData />

        <Flex
          sx={{
            flexDirection: ["column", "row"],
            flexWrap: ["nowrap", "wrap", "nowrap"],
            alignItems: "start",
            justifyContent: ["center", "start"],
            mt: 10,
          }}
        >
          <Link
            to={privacyUrl || "/privacy"}
            sx={{
              alignSelf: "start",
              mb: [4, 0],
              mr: 6,
              whiteSpace: ["initial", "nowrap"],
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to={termsUrl || "/terms"}
            sx={{ alignSelf: "start", whiteSpace: ["initial", "nowrap"] }}
          >
            Terms and Conditions
          </Link>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
