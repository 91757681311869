import React from "react";
import { Text } from "theme-ui";

const VerticalDivider = ({ sx = {}, ...props }) => (
  <Text
    sx={{
      variant: "text.body2",
      color: "lineDetail",
      ...sx,
    }}
    {...props}
  >
    |
  </Text>
);

export default VerticalDivider;
