import React from 'react';
import { getImage } from "gatsby-plugin-image";
import { Text, Heading, Flex, Paragraph } from "theme-ui";
import { format } from "date-fns";

import Card from "components/card/Card";
import {  useVariantByBreakpoint } from "utils";

export default function OfferCard({ image, title, summary, expirationDate }) {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Card
      type="white"
      orientation="portrait"
      size={variantSelector(["medium", "medium", "large"])}
      image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
      contentSx={{ py: 4, px: [3, 3, 4] }}
    >
      <Flex sx={{ flexDirection: "column", height: "full" }}>
        <Heading as="h6" variant="subtitle1">
          {title}
        </Heading>
        <Paragraph
          variant="body2"
          sx={{
            mt: 2,
            mb: 4,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {summary}
        </Paragraph>
        {expirationDate && (
          <Text sx={{ mt: "auto" }} variant="body3" color="text.secondary">
            {`Until ${format(new Date(expirationDate), "d MMMM yyyy")}`}
          </Text>
        )}
      </Flex>
    </Card>
  );
}
