import React from "react";
import { Box, Flex, Heading, Paragraph } from "theme-ui";

import Link from "components/Link";
import Icon from "components/Icons";
import Image from "components/Image";

import { useVariantByBreakpoint } from "utils";
import { cityPageUrl } from "utils/urls";

const CitySection = ({ city, sx, ...props }) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Flex
      sx={{
        flexDirection: ["column", "row-reverse"],
        justifyContent: "center",
        minHeight: 120,
        gap: [0, 10],
        mr: [0, "columnMargin"],
        ...sx,
      }}
      {...props}
    >
      <Image
        image={city.image}
        sx={{
          zIndex: "negative",
          width: ["100%", "47%", "60%"],
          mb: [4, 0],
        }}
        alt="City image"
      />
      <Box
        sx={{
          display: ["block", "flex"],
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          minWidth: [null, 44, 150],
          px: [4, 0],
          ml: [0, "contentSpace"],
        }}
      >
        <Link to={cityPageUrl(city)} sx={{ mb: 4 }}>
          {city.name}
        </Link>
        <Heading
          as="h3"
          variant={variantSelector(["heading5", "heading4", "heading3"])}
          sx={{ mb: 4 }}
        >
          {city.caption}
        </Heading>
        <Paragraph variant="body1" sx={{ mb: 10 }}>
          {city.description}
        </Paragraph>
        <Link
          to={cityPageUrl(city)}
          variant="buttons.primary"
          sx={{ mr: "auto" }}
        >
          Experiences in {city.name}
          <Icon sx={{ ml: 3 }} icon="fas fa-arrow-right" />
        </Link>
      </Box>
    </Flex>
  );
};

export default CitySection;
