/** @jsx jsx */
import React, { useState, useEffect } from "react";
import {
  Box,
  jsx,
  Text,
  Heading,
  Flex,
  Paragraph,
  Button,
  Divider,
  Grid,
} from "theme-ui";

import Public from "@material-ui/icons/Public";
import Call from "@material-ui/icons/Call";
import Directions from "@material-ui/icons/Directions";
import Email from "@material-ui/icons/Email";

import { formatInTimeZone } from "date-fns-tz";
import kebabCase from "lodash/kebabCase";
import { navigate } from "gatsby";
import filter from "lodash/fp/filter";
import flow from "lodash/fp/flow";
import omit from "lodash/fp/omit";

import InfoSections from "components/InformationSections";
import MapSection from "components/MapSection";
import ReadMoreModal from "components/ReadMoreModal";
import Layout from "components/Layout";
import Card from "components/card/Card";
import Slider from "components/Slider";
import PhotoGallery from "components/PhotoGallery";
import TagsBox from "components/TagsBox";
import OfferCard from "components/card/OfferCard";
import StickyMenu from "components/StickyMenu";
import Icon from "components/Icons";
import Link from "components/Link";
import Image from "components/Image";
import ExternalSource from "vendors/components/ExternalSource";
import TeamSlider from "vendors/components/TeamSlider";
import PropertyFacilitiesSlider from "explore/components/PropertyFacilitiesSlider";
import VendorSlider from "explore/components/VendorSlider";
import CitySection from "explore/components/CitySection";

import { cityPageUrl, propertyPageUrl } from "utils/urls";
import {
  useVariantByBreakpoint,
  getExternalMapUrl,
  stringFormatForUrl,
  useWebchat,
} from "utils";

export default function PropertyPage({ property, logos, heroImageGallery }) {
  const PAGE_NAME = property?.name;
  const city = property.city;

  const recommendedVendors = property?.vendors;

  const informationSections = property?.infoSections.map((section) => {
    return {
      id: `${kebabCase(section.title)}-section`,
      text: section.title,
      exists: true,
    };
  });

  const menuSections = [
    {
      id: "overview-section",
      text: "Overview",
      exists: true,
    },
    {
      id: "facilities-section",
      text: "On Property",
      exists: property?.facilities.length > 0,
    },
    {
      id: "city-section",
      text: "Our Neighbourhood",
      exists: true,
    },
    {
      id: "rooms-section",
      text: "Rooms",
      exists: property?.rooms.length > 0,
    },
    {
      id: "team-section",
      text: property?.team?.title,
      exists: property?.team?.profiles.length > 0,
    },
    {
      id: "location-section",
      text: "Location & Contact",
      exists:
        property.contactInfo?.location?.latitude &&
        property.contactInfo?.location?.longitude,
    },
    ...informationSections,
    {
      id: "offers-section",
      text: "Offers",
      exists: property?.offers.length > 0,
    },
    {
      id: "stayConnected-section",
      text: "Stay Connected",
      exists: property?.stayConnected.length > 0,
    },
    {
      id: "recommended-experiences-section",
      text: "Recommended Experiences",
      exists: property?.vendors.length > 0,
    },
  ];

  const BREADCRUMB_LINKS = [
    { text: "Explore", to: "/" },
    city.name && {
      text: city.name,
      to: `/explore/destination/${stringFormatForUrl(city.name)}`,
    },
    {
      text: property.name,
      to: propertyPageUrl(property),
    },
  ].filter(Boolean);

  const isOnlyContactInfo =
    flow(omit(["id"]), filter(Boolean))(property?.contactInfo).length < 2;

  const variantSelector = useVariantByBreakpoint();

  const isMobile =
    variantSelector(["mobile", "tablet", "desktop"]) === "mobile";

  const getLocalTime = () =>
    formatInTimeZone(new Date(), property.timezone, "hh:mm a");

  const [localTime, setLocalTime] = useState(getLocalTime());
  const [showModal, setShowModal] = useState(false);
  const [showModalContent, setShowModalContent] = useState({});

  const handleOnPropertyClick = (tag) => {
    // navigate(
    //   `/${stringFormatForUrl(
    //     property.name
    //   )}/facilities?facilityTagId=${tag.id}`
    // );
    // navigate(`/`);
  };

  useWebchat(property.code, property.name);

  useEffect(() => {
    const interval = setInterval(() => setLocalTime(getLocalTime(), 1000));
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      {heroImageGallery.length > 0 ? (
        <PhotoGallery
          medias={heroImageGallery}
          sx={{
            maxHeight: [60, 120, 150],
            width: "100%",
            maxWidth: "gallery",
            mb: 20,
            mx: "auto",
          }}
          className="fullSize"
        />
      ) : (
        <PhotoGallery
          medias={[property.heroImage]}
          sx={{
            maxHeight: [60, 120, 150],
            width: "100%",
            maxWidth: "gallery",
            mb: 20,
            mx: "auto",
          }}
          className="fullSize"
        />
      )}

      <Grid
        columns={["auto", "1fr auto 1fr"]}
        sx={{
          my: 10,
          justifyContent: "center",
        }}
      >
        {city.name && <Link
          variant={variantSelector(["links.text.small", "links.text"])}
          sx={{ mb: 1, display: ["none", "inline-flex"] }}
          to={cityPageUrl(city)}
        >
          {city.name}
        </Link>}

        <Box sx={{ maxHeight: "120px", gridColumn: ["auto","2"] }}>
          <Image
            image={logos.light}
            alt="Property Logo"
            objectFit="contain"
            sx={{ height: "120px", objectFit: "contain" }}
          />
        </Box>
      </Grid>

      <Heading as="h2" variant="heading2" sx={{ mb: 4, textAlign: "center" }}>
        {property.name}
      </Heading>

      <Flex
        sx={{
          justifyContent: "center",
          mb: 10,
        }}
      >
        <Box
          sx={{
            variant: "subtitle1",
            display: ["flex", "inline-flex", "inline-flex"],
            justifyContent: "center",
            gap: ["50px", 4, 4],
            ml: [0, 4, 4],
          }}
        >
          {property?.contactInfo?.website && (
            <ExternalSource
              to={property.contactInfo.website}
              shouldHideVerticalDivider={true}
              icon={Public}
              sx={isMobile && { fontWeight: "400" }}
            >
              {isMobile ? "Website" : "Visit Website"}
            </ExternalSource>
          )}
          {property?.contactInfo?.location && (
            <ExternalSource
              to={getExternalMapUrl(property.contactInfo.location)}
              icon={Directions}
              shouldHideVerticalDivider={isMobile || isOnlyContactInfo}
              sx={isMobile && { fontWeight: "400" }}
            >
              Directions
            </ExternalSource>
          )}

          {!isMobile && property?.contactInfo?.email && (
            <ExternalSource
              to={`mailto:${property.contactInfo.email}`}
              icon={Email}
              shouldHideVerticalDivider={isMobile || isOnlyContactInfo}
              sx={isMobile && { fontWeight: "400" }}
            >
              Email
            </ExternalSource>
          )}

          {property?.contactInfo?.phone && (
            <ExternalSource
              to={`tel:${property.contactInfo.phone}`}
              icon={Call}
              shouldHideVerticalDivider={isMobile || isOnlyContactInfo}
              sx={isMobile && { fontWeight: "400" }}
            >
              Call
            </ExternalSource>
          )}
        </Box>
      </Flex>

      {/* LOCAL TIME BANNER */}

      <Box>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: ["80%", "60%", "60%"],
            mx: "auto",
            mb: 20,
          }}
        >
          <Divider width="25%" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <Heading as="h5" variant="heading5">
              {localTime}
            </Heading>
            <Text
              variant="body3"
              sx={{ color: "text.secondary", textTransform: "uppercase" }}
            >
              Local Time
            </Text>
          </Box>
          <Divider width="25%" />
        </Flex>
      </Box>

      {/* DESCRIPTION BOX */}
      <Flex
        id={"overview-section"}
        sx={{
          gap: "80px",
          flexDirection: ["column", "column", "row"],
          mb: "100px",
          scrollMarginTop: [32, null, 18],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: ["100%", "70%", "70%"],
          }}
        >
          <Heading as="h5" variant="heading5" sx={{ mb: 4 }}>
            Escape the urban bustle in your own spacious sanctuary with
            floor-to-ceiling landscape views.
          </Heading>
          <Text variant="body1" sx={{}}>
            {property.description
              ? property.description
              : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum ...`}
          </Text>
        </Box>

        <Box
          sx={{
            display: "flex",
            mb: 4,
            flexDirection: "column",
            maxWidth: ["100%", "70%", "70%"],
          }}
        >
          <Text variant="body3" sx={{ textTransform: "uppercase", mb: 4 }}>
            On Property
          </Text>

          <TagsBox
            tags={property.facilities}
            handleClick={handleOnPropertyClick}
            facilityTypePlurals={facilityTypePlurals}
          />
        </Box>
      </Flex>

      <StickyMenu items={menuSections.filter(({ exists }) => exists)} />

      {property?.facilities.length > 0 && (
        <>
          <Heading
            id={"facilities-section"}
            sx={{
              scrollMarginTop: [32, null, 18],
            }}
            as="h4"
            variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          >
            On Property
          </Heading>
          <PropertyFacilitiesSlider
            facilities={property.facilities}
            facilityTypePlurals={facilityTypePlurals}
          />
          <Divider sx={{ my: [10, 15, null] }} />
        </>
      )}

      <Heading
        id={"city-section"}
        sx={{
          mb: [3, 6],
          scrollMarginTop: [32, null, 18],
        }}
        as="h4"
        variant={variantSelector(["subtitle1", "heading5", "heading4"])}
      >
        Our Neighbourhood
      </Heading>

      <CitySection
        city={city}
        sx={{
          mx: "auto",
          mt: [0, 10],
          mb: [10, null, 20],
        }}
        className="fullSize"
      />

      <Divider sx={{ my: [10, 15, null] }} />

      {property?.rooms.length > 0 && (
        <>
          {showModal && showModalContent.key === "rooms" && (
            <ReadMoreModal
              {...showModalContent}
              onClose={() => {
                setShowModal(false);
                setShowModalContent({});
              }}
            />
          )}
          <Heading
            id={"rooms-section"}
            sx={{ scrollMarginTop: [32, null, 18] }}
            as="h4"
            variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          >
            Rooms
          </Heading>
          <Slider className="fullSize" sx={{ mt: 6 }}>
            {property.rooms.map(({ id, name, description, heroImage }) => {
              return (
                <Card
                  type="white"
                  orientation="portrait"
                  size={variantSelector(["large", null, "extra_large"])}
                  image={heroImage}
                  sx={{
                    height: "full",
                    cursor: "pointer",
                  }}
                  contentSx={{ px: 4 }}
                  onClick={() => {}}
                  key={id}
                >
                  <Flex
                    sx={{
                      flexDirection: "column",
                      height: "full",
                      py: [1, null, 3],
                    }}
                  >
                    <Heading
                      as="h4"
                      variant={variantSelector([
                        "heading6",
                        "heading6",
                        "heading5",
                      ])}
                      sx={{}}
                    >
                      {name}
                    </Heading>
                    <Paragraph
                      variant={variantSelector(["body2", null, "body1"])}
                      sx={{
                        mt: 4,
                        maxHeight: "4.5em",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {description}
                    </Paragraph>
                    <Flex sx={{ mt: "auto", pt: 6, justifyContent: "end" }}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={() => {
                          setShowModalContent({
                            section: "Rooms",
                            title: name,
                            image,
                            description,
                            key: "rooms",
                          });
                          setShowModal((showModal) => !showModal);
                        }}
                      >
                        Details
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
          </Slider>
          <Divider sx={{ my: [10, 15, null] }} />
        </>
      )}

      {property?.team?.profiles.length > 0 && (
        <>
          <TeamSlider team={property?.team} />
          <Divider sx={{ my: [10, 15, null] }} />
        </>
      )}

      {property.contactInfo?.location?.latitude &&
        property.contactInfo?.location?.longitude && (
          <>
            <Heading
              id="location-section"
              as="h3"
              variant={variantSelector(["subtitle1", "heading5", "heading4"])}
              sx={{ mb: 6, scrollMarginTop: [32, null, 18] }}
            >
              Location & Contact
            </Heading>
            <MapSection
              locationInfo={property}
              sx={{
                mb: 15,
              }}
            />
            <Divider sx={{ my: [10, 15, null] }} />
          </>
        )}

      {property?.infoSections && (
        <InfoSections
          infoSections={property.infoSections}
          showModal={showModal}
          setShowModal={setShowModal}
          showModalContent={showModalContent}
          setShowModalContent={setShowModalContent}
        />
      )}

      {property?.offers.length > 0 && (
        <>
          <Heading
            id="offers-section"
            as="h4"
            sx={{ scrollMarginTop: [32, null, 18] }}
            variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          >
            Offers
          </Heading>
          <Slider className="fullSize" sx={{ mt: 6 }}>
            {property.offers.map(
              ({
                title,
                summary,
                id,
                details,
                image,
                book_url,
                expires_at: expirationDate,
              }) => {
                return (
                  <OfferCard
                    title={title}
                    summary={summary}
                    details={details}
                    image={image}
                    expirationDate={expirationDate}
                    key={id}
                  />
                );
              }
            )}
          </Slider>
          <Divider sx={{ my: [10, 15, null] }} />
        </>
      )}

      {property?.stayConnected.length > 0 && (
        <>
          <Heading
            id="stayConnected-section"
            as="h4"
            sx={{ scrollMarginTop: [32, null, 18] }}
            variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          >
            Stay Connected
          </Heading>
          <Flex sx={{ mt: [4, 4, 6] }}>
            {property?.stayConnected.map(
              (social) =>
                social?.icon && (
                  <Button
                    variant="buttons.text.large"
                    sx={{ fontSize: "1.5rem" }}
                  >
                    <Icon
                      icon={social.icon}
                      onClick={() => {
                        navigate(social?.url);
                      }}
                    />
                  </Button>
                )
            )}
          </Flex>
          <Divider sx={{ my: [10, 15, null] }} />
        </>
      )}

      {property?.vendors.length > 0 && (
        <>
          <Heading
            id="recommended-experiences-section"
            as="h4"
            sx={{ scrollMarginTop: [32, null, 18] }}
            variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          >
            Recommended Experiences
          </Heading>
          <VendorSlider
            vendorList={recommendedVendors}
            className="fullSize"
            sx={{ mt: 6, mb: 20 }}
          />
        </>
      )}
    </Layout>
  );
}

const facilityTypePlurals = {
  Restaurant: "Restaurants",
  Pool: "Pools",
  "Business Centre": "Business Centres",
  Experience: "Experiences",
  Florist: "Florists",
  Salon: "Salons",
  Store: "Stores",
  Activity: "Activities",
  "Executive Club": "Executive Clubs",
  "Meeting Room": "Meeting Rooms",
  Vehicle: "Vehicles",
  Fitness: "Fitness",
  Kids: "Kids",
  Golf: "Golf",
  Parking: "Parking",
  Spa: "Spa",
  "Other Dining": "Other Dining",
  "In Room Dining": "In Room Dining",
  Other: "Other",
};
