/** @jsx jsx */
import { Box, jsx } from "theme-ui";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const FavoriteButton = ({ isFavorite, onClick }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "0",
        zIndex: "50",
        background: "rgba(49, 34, 14, 0.54)",
        borderRadius: "rounded",
        margin: "8px",
        display: "flex",
        padding: "10px 11px",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <FavoriteBorderIcon sx={{ color: "systemWhite" }} />
    </Box>
  );
};

export default FavoriteButton;
