/** @jsx jsx */
import { Box, jsx } from "theme-ui";

const Pill = ({ text }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: "0",
        zIndex: "50",
        background: "#EEF7FB",
        borderRadius: "rounded",
        padding: "6px 16px",
        margin: "12px",
      }}
    >
      {text}
    </Box>
  );
};

export default Pill;
