import React from "react";
import { Box, Flex } from "theme-ui";

import Pill from "components/card/Pill";
import FavoriteButton from "components/card/FavoriteButton";
import { CARD_RESOLUTIONS } from "components/card/sizes";
import Image from "components/Image";

const CardWithBlackBackground = ({
  children,
  size,
  orientation,
  image,
  pill,
  onClickFav,
}) => {
  return (
    <Box
      sx={{
        variant: `cards.black_background`,
        width: CARD_RESOLUTIONS[orientation][size]?.width,
        height: CARD_RESOLUTIONS[orientation][size]?.height,
        display: "flex",
      }}
    >
      <Box sx={{ position: "relative" }}>
        {["large", "extra_large"].includes(size) && (
          <Box
            sx={{
              height: "full",
              width: "full",
              position: "absolute",
            }}
          >
            {pill && <Pill text={pill} />}
            {onClickFav && <FavoriteButton onClick={onClickFav} />}
          </Box>
        )}
        <Image
          image={image}
          alt="Card image"
          sx={{
            height: "full",
            width: "full",
            objectFit: "cover",
            zIndex: "-10",
            borderRadius: 1,
          }}
        />
        <Flex
          sx={{
            position: "absolute",
            width: "full",
            bottom: 0,
            color: "systemWhite",
            justifyContent: "center",
          }}
        >
          {children}
        </Flex>
      </Box>
    </Box>
  );
};

export default CardWithBlackBackground;
