import React from "react";
import { graphql } from "gatsby";

import PropertyPage from "explore/PropertyPage";
import SEO from "components/SEO";

import getImage from "utils/getImage";

export default function Property({ data }) {
  const property = {
    ...data.strapiProperty,
    heroImage: {
      image: getImage(data.strapiProperty.heroImage),
      type: "image",
    },
    team: {
      ...data.strapiProperty?.team,
      profiles:
        data.strapiProperty?.team?.profiles?.map((profile) => ({
          ...profile,
          description: profile?.description?.data?.description,
          image: getImage(profile.image),
        })) ?? [],
    },
    city: {
      ...data.strapiProperty?.city,
      image: getImage(data.strapiProperty?.city?.image),
    },
    facilities: data?.allStrapiFacility?.nodes,
    rooms: data?.allStrapiRoom?.nodes.map((room) => ({
      ...room,
      heroImage: getImage(room.heroImage),
    })),
    vendors: data?.allStrapiVendor?.nodes.map((vendor) => {
      return {
        ...vendor,
        image: getImage(
          vendor.heroImage,
          data.strapiRecommendsConfig?.defaultVendorImage
        ),
      };
    }),
  };
  const logos = {
    light: getImage(data.strapiProperty.logos.lightMode),
    dark: getImage(data.strapiProperty.logos.darkMode),
  };

  const gallery = data?.strapiProperty?.heroImageGallery
    .map((node) => {
      if (node?.video) {
        return {
          url: node.video[0].localFile?.publicURL,
          type: "video",
        };
      }
      return {
        image: getImage(node?.image),
        type: "image",
      };
    })
    .filter((media) => media.url || media.image)
    .sort((media1, media2) => {
      if (media1.type === "video") {
        return media2.type === "video" ? 0 : -1;
      }
      if (media2.type === "video") {
        return 1;
      }
      return 0;
    });

  return (
    <PropertyPage
      property={property}
      logos={logos}
      heroImageGallery={gallery}
    />
  );
}

export const query = graphql`
  query PropertyPageQuery(
    $slug: String
    $facilityIds: [Int]
    $roomIds: [Int]
    $vendorIds: [Int]
  ) {
    strapiProperty(slug: { eq: $slug }) {
      id: strapi_id
      name
      timezone
      slug
      roomIds: room_ids
      vendorIds: vendor_ids
      code
      city {
        slug
        name
        caption
        description
        id
        image: hero_image {
          ...strapiMediaFragment
        }
      }
      contactInfo: contact_info {
        id
        email: email_address
        phone: phone_number
        website: website_url
        location {
          longitude
          latitude
        }
      }
      description
      facility_ids
      strapiId: strapi_id
      logos {
        id
        lightMode: light_mode {
          ...strapiMediaFragment
        }
        darkMode: dark_mode {
          ...strapiMediaFragment
        }
      }
      heroImage: hero_image {
        ...strapiMediaFragment
      }
      heroImageGallery: gallery {
        ...galleryFragment
      }
      team: meet_the {
        title
        profiles {
          description {
            data {
              description
            }
          }
          subtitle
          title
          image {
            ...strapiMediaFragment
          }
        }
      }
      offers {
        id
        summary
        title
        details {
          data {
            details
          }
        }
        image {
          ...strapiMediaFragment
        }
        book_url
        expires_at
      }
      stayConnected: stay_connected {
        id
        type
        url
        icon
      }
      infoSections: information_sections {
        ...informationSectionsFragment
      }
    }
    allStrapiFacility(filter: { strapi_id: { in: $facilityIds } }) {
      nodes {
        id: strapi_id
        name
        slug
        booking_url
        description
        display_name
        type
        heroImage: hero_image {
          ...strapiMediaFragment
        }
        gallery {
          ...galleryFragment
        }
      }
    }
    allStrapiRoom(
      filter: { strapi_id: { in: $roomIds } }
      sort: { fields: sort_order }
    ) {
      nodes {
        id
        name
        display_name
        description
        strapi_id
        heroImage: hero_image {
          ...strapiMediaFragment
        }
      }
    }
    allStrapiVendor(filter: { strapi_id: { in: $vendorIds } }) {
      nodes {
        slug
        axpVendorId: axp_vendor_id
        id
        strapi_id
        name
        display_name
        description
        starts_on
        ends_on
        contactInfo: contact_info {
          address {
            address_line_1
            city
            country
          }
        }
        heroImage: hero_image {
          ...strapiMediaFragment
        }
        contact_info {
          address {
            city
          }
        }
        property_id
      }
    }
    strapiRecommendsConfig {
      defaultVendorImage: default_vendor_image {
        ...strapiMediaFragment
      }
    }
  }
`;

export const Head = ({ data }) => (
  <SEO title={data?.strapiProperty?.name || "Property"} />
);
