import { Script } from "gatsby";
import React from "react";

const SEO = ({ title, children }) => {
  return (
    <>
      <title>{title}</title>
      {children}
      <Script>
        {process.env.GA_TRACKING_ID
          ? `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GA_TRACKING_ID}');
        `
          : `console.info("missing tracking id")`}
      </Script>
    </>
  );
};

export default SEO;
