import React, { forwardRef } from "react";
import { Box, Grid, IconButton } from "theme-ui";
import { useSlider } from "utils";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const MenuSlider = forwardRef(({ sx, className, gap = 2, children }, ref) => {
  const { sliderRef, scrollTo, scrollStatus, isOverflown } = useSlider();

  const showIf = (side) =>
    scrollStatus !== side &&
    scrollStatus !== null &&
    isOverflown(sliderRef.current.lastElementChild);

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
      className={className}
    >
      <Grid
        sx={{
          gridAutoFlow: "column",
          gridAutoColumns: "max-content",
          gap,
          overflowY: "hidden",
          width: "full",
          whiteSpace: "nowrap",
          scrollSnapType: "y mandatory",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        ref={(element) => {
          sliderRef.current = element;
          if (ref) ref.current = element;
        }}
      >
        {children}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          display: showIf("left") ? "flex" : "none",
          height: "full",
        }}
      >
        <IconButton
          variant="buttons.icon.withoutBackground"
          sx={{ display: ["none", null, "flex"], background: "background" }}
          onClick={scrollTo("left")}
        >
          <ChevronLeft />
        </IconButton>
        <Box
          sx={{
            width: 12,
            height: "full",
            background: "menuSliderGradientLeft",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: showIf("right") ? "flex" : "none",
          height: "full",
        }}
      >
        <Box
          sx={{
            width: 12,
            height: "full",
            background: "menuSliderGradientRight",
          }}
        ></Box>
        <IconButton
          variant="buttons.icon.withoutBackground"
          sx={{ display: ["none", null, "flex"], background: "background" }}
          onClick={scrollTo("right")}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
});

export default MenuSlider;
