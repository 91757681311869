import { Box } from "theme-ui";
import React from "react";
import Image from "components/Image";

const MediaContent = ({ media, ...props }) => {
  if (media?.type === "video") {
    return (
      <Box as="video" autoPlay loop muted variant="video" {...props}>
        <Box as="source" src={media.url} />
      </Box>
    );
  }

  return <Image image={media?.image} {...props} />;
};

export default MediaContent;
