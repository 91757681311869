export const CARD_RESOLUTIONS = {
  portrait: {
    small: {
      width: "152px",
      height: "260px",
    },
    medium: {
      width: "252px",
      height: "352px",
    },
    large: {
      width: "320px",
      height: "340px",
    },
    extra_large: {
      width: "480px",
      height: "460px",
    },
  },

  landscape: {
    medium: {
      width: "252px",
      height: "140px",
    },
    large: {
      width: "343px",
      height: "140px",
    },
    extra_large: {
      width: "600px",
      height: "272px",
    },
  },
};

export const IMAGE_RESOLUTIONS = {
  portrait: {
    small: {
      width: "152px",
      height: "120px",
    },
    medium: {
      width: "252px",
      height: "240px",
    },
    large: {
      width: "320px",
      height: "272px",
    },
    extra_large: {
      width: "480px",
      height: "272px",
    },
  },
  landscape: {
    medium: {
      width: "85px",
      height: "140px",
    },
    large: {
      width: "102px",
      height: "140px",
    },
    extra_large: {
      width: "240px",
      height: "272px",
    },
  },
};
