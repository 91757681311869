import { getAxpFEUrl, getBrandId } from "./envvars";
import { format } from "date-fns";

export const axpItineraryUrl = (profileId) =>
  `${getAxpFEUrl()}/itinerary/${getBrandId()}/${profileId}?itinerary_embed=true`;

const DATE_FORMAT = "yyyy-MM-dd";

export function experiencePageUrl(city, { tagId, from, to } = {}) {
  const search = new URLSearchParams();

  if (tagId) search.append("vendorTagId", tagId);
  if (from && to) {
    search.append("from", format(from, DATE_FORMAT));
    search.append("to", format(to, DATE_FORMAT));
  }
  return `/destination/${city.slug}/experiences?${search.toString()}`;
}

export function cityPageUrl(city) {
  if (city?.slug) return `/destination/${city.slug}`;
  return "";
}

export function vendorPageUrl(vendor) {
  return `/vendors/${vendor.slug}`;
}

export function facilityPageUrl(facility) {
  return `/destination/facility/${facility.slug}`;
}

export function propertyPageUrl(property) {
  if (property?.slug) return `/destination/property/${property?.slug}`;
  return "";
}
