import React from "react";
import { Box } from "theme-ui";
import Footer from "components/Footer";
import NavBar from "components/navbar";

const Layout = ({ children, page, breadcrumbs, sx, ...props }) => {
  return (
    <Box
      as="main"
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr minmax(auto, 75rem) 1fr",
        gridTemplateRows: "min-content",
        minHeight: "100vh",
        "& > :not(.fullSize):not(.outside)": {
          gridColumn: "2",
          mx: [4, 10],
        },
        "& > .fullSize": {
          gridColumn: "1 / span 3",
        },
        ...sx,
      }}
      {...props}
    >
      <NavBar
        currentPage={page}
        breadcrumbs={breadcrumbs}
        className="fullSize"
        sx={{}}
      />
      {children}
      <Footer
        className="fullSize"
        sx={{
          mt: "auto",
        }}
      />
    </Box>
  );
};

export default Layout;
